@import '../../../core/assets//sass/layout/mixins.scss';
@import 'react-phone-input-2/lib/style.css';

.settings-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.625rem;

  .card {
    border-radius: 4px;
    background-color: #f8fbff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 120px;
    height: 120px;

    .pencil-container {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.company-container {
  width: 420px;
  background-color: #ffffff;
  border-radius: 4px;

  .cardC {
    border-radius: 4px;
    background-color: #f8fbff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 120px;
    height: 120px;
    margin: 15px 0 20px 16px;
    padding: 0;

    .pencil-container {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.locale-container {
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.625rem;
}

.social-media-icon {
  margin-left: 10px;
}

.custom-table {
  width: 100%;

  td {
    width: 10%;
  }

  .custom-thead {
    color: #8898a6;
  }

  .custom-button {
    height: 45px;
  }
}

.custom-table-project {
  td {
    width: 32%;
  }
}

.kebab-menu-dropdown {
  position: absolute !important;
  bottom: calc(80%);
  left: 0;
  z-index: 999;

  .dropdown-items {
    &:hover {
      background-color: #e1e7ed;
    }
  }
}

.bi-three-dots-vertical::after {
  content: none;
}

@mixin transition {
  transition: all 200ms ease;
}

.kebab-pin-container {
  background-color: #f5f8fa;
  border-radius: 5px;
  color: #8898a6;
}

.parent-accordion {
  .accordion-button {
    height: 60px !important;

    // background-color: white;
    &:not(.hideIt) {
      &::after {
        content: '';
        background-image: url('../../../../public/media/icons/setting/side_arrow.svg');
        transform: rotate(90deg);
      }
    }

    &::after {
      content: '';
      background-image: url('../../../../public/media/icons/setting/side_arrow.svg');
      width: 34px;
      height: 34px;
      background-size: auto;
      transform: rotate(0deg);
    }
  }
}

.radio-btn {
  width: 21px;
  height: 21px;
}

.button-icon {
  width: 28px;
  height: 24px;
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.save-btn {
  background-color: #4776e6;
}

.transfer-ownership-table {
  td {
    width: 50%;
  }
}

.transfer-ownership-td {
  color: #8898a6;
}

.radio-button {
  background-color: #f3f6f9;
  border: none;
}

.table-body-td {
  vertical-align: middle;
}

.title-notification {
  line-height: 20px;
  color: #383940;
}

.linkedin {
  background-color: #f1faff;
  max-width: 680px;
  padding: 20px;
  border-radius: 8px;
  margin-left: 0px !important;
  font-size: 14px;
  font-weight: 600;
}

.payment-method {
  font-size: 14px;
  font-weight: 500;
}

.stripe-color {
  color: var(--primary-color);
}

.dot {
  height: 5px;
  min-width: 5px;
  max-width: 5px;
  background-color: var(--gray-color);
  border-radius: 50%;
}

.card-brand {
  background-color: #d9d9d9;
  width: 32px;
  height: 21px;
}

.color-grey {
  color: #808080;
}

.table-border-top-left {
  border-top-left-radius: 0.625rem;
}

.table-border-top-right {
  border-top-right-radius: 0.625rem;
}

.table-border-bottom-left {
  border-bottom-left-radius: 0.625rem;
}

.table-border-bottom-right {
  border-bottom-right-radius: 0.625rem;
}

.profile-country .form-select {
  width: 98% !important;
}

@media (max-width: 450px) {
  .profile-country .form-select {
    width: 100% !important;
  }
  .react-tel-input {
    margin-bottom: 10px !important;
  }
}
.profile-email {
  margin-bottom: -20px;
  font-weight: 600;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 46px !important;
}

.team-kebab-menu {
  position: absolute !important;
  left: 2rem;
  top: -3.5rem;
  z-index: 999;

  .dropdown-items {
    &:hover {
      background-color: #e1e7ed;
    }
  }
}
