.thumbnail-image,
.thumbnail-video,
.thumbnail-pdf,
.thumbnail-text {
  width: 105px;
  height: 105px;
  transition: filter 0.5s ease-in-out;
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
}

.extension-background {
  background: #ffffff !important;
}

.document-bg-color {
  background-color: #b8d2e4 !important;
}

.video-bg-color {
  background-color: #b8d2e4 !important;
}
.extension-width {
  max-width: 40px;
}

.text-wrap {
  overflow-wrap: break-word;
}

.thumbnail-image-container {
  position: relative;
  width: 105px;
  height: 105px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 105px;
  height: 105px;
  background-color: rgba(184, 210, 228, 0.8);
  z-index: 1;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

@media (min-width: 992px) {
  .image-overlay {
    width: auto;
    height: 60vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
  }
}

.hide-arrows {
  .carousel-control-prev {
    display: none;
  }

  .carousel-control-next {
    display: none;
  }
}

@media (max-width: 990px) {
  .image-overlay {
    height: 40vh;
  }
}

.cross-icon {
  position: fixed;
  top: 0px;
  right: 0px;
  cursor: pointer;
  width: 70px !important;
}

.carousel-image-container img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.carousel-container {
  height: 70vh;
}

@media (min-width: 1600px) {
  .carousel-container {
    height: 73vh;
  }
}

@media (min-width: 1600px) {
  .carousel-image-container img {
    max-height: 73vh;
  }
}

.image-container {
  position: absolute;
  bottom: 10px;
  left: 45px;
  z-index: 100 !important;
}

.close-btn-container {
  position: absolute;
  top: 3px;
  right: 6px;
  z-index: 100 !important;
}

.carousel-container {
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  height: 40px;
  width: 40px;
  background-color: #cccccc !important;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  border: 1px solid rgb(244, 245, 244);
}

.carousel-control-prev-icon {
  width: 24px;
  height: 24px;
}

.carousel-control-next-icon {
  width: 24px;
  height: 24px;
}

.zoom-btn-container {
  position: absolute;
  bottom: 30px;
  right: 3px;
  z-index: 100 !important;
}
