// Phone
$screen-sm-max: 1024px;

// Tablets
$screen-tab-max: 1024px;

// Phone
@mixin phone {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Tablets
@mixin tabalet {
  @media (max-width: #{$screen-tab-max}) {
    @content;
  }
}

.founder-crate-bg {
  background-image: url('../../../../public/media/auth/dripfunnelBackground.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.founder-crate-background {
  @include phone {
    padding: 33px 0;
  }
}

.col-3-5 {
  width: 35.7%;

  @include phone {
    width: 100%;
  }
}

.btn {
  .btn-primary {
    background-color: #0077b5 !important;
  }
}

.btn {
  .btn-linkedin {
    background-color: #0077b5;
  }
}

.form-label {
  color: #000 !important;
}

.mt-24px {
  margin-top: 24px;
}

.mb-29px {
  margin-bottom: 29px;
}

.text-clr477 {
  color: #4776e6;
}

.tooltip {
  --bs-tooltip-max-width: 350px;
}

.is-invalid-local,
.is-invalid-local:focus {
  border-color: #f1416c;
  padding-right: calc(1.5em + 1.55rem);
  background-color: rgba(241, 65, 108, 0.2) !important;
}

.is-valid-local,
.is-valid-local:focus {
  border-color: #50cd89;
  padding-right: calc(1.5em + 1.55rem);
}

.founder-create-logo > img {
  height: 52px;
  margin-bottom: 32px;
}

.added_height {
  height: 100vh;
}

.bw-layout-bg {
  background-image: url('../../../../public/media/auth/dripfunnelBackground.jpg');
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.layout {
  background-color: white;
}

.welcome {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.info {
  flex-grow: 0;
}

.email-container {
  display: inline-flex;
  white-space: nowrap;
}

.microsoftLoginBtn {
  background-color: #f1faff !important;
  color: #3b956a !important;
  &:hover {
    background-color: #2b3351 !important;
    border: 1px solid #2b3351 !important;
    color: white !important;
  }
}
