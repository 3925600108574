.bg-white {
  background-color: #ffffff !important;
}

.w-40 {
  width: 40%;
}

.project-description-container {
  height: 53px;
  overflow: hidden;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.project-card {
  width: 268px;
  background-color: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  box-shadow: 12px 12px 12px 0 rgba(195, 195, 195, 0.25);
  overflow: hidden;
}

.column-width {
  width: 265px !important;
}

.column-height {
  height: 700px !important;
  overflow-y: scroll;
  border-radius: 4px;
  background-color: rgba(19, 26, 54, 0.9) !important;
}

.column-title-box {
  height: 50px;
  border-radius: 8px 8px 0 0;
}

.task-id {
  text-decoration: underline;
  color: var(--task-id-color) !important;
}

.task-card {
  border: 1px solid white !important;
  background-color: var(--task-bg-color);
  min-height: 123px;
}

.task-description {
  max-height: 250px !important;
  overflow-y: scroll;
}

.anchor-color {
  color: #181c32 !important;
}

.task-board-width {
  width: 1400px;
}

.task-list-top-section {
  margin-bottom: 24px !important;
}

.title-color {
  color: var(--title-color) !important;
}

.description-color {
  color: var(--description-color) !important;
}

.add-task-button {
  margin-top: -7px !important;
}

.search {
  width: 294px !important;
  height: 42px !important;
  border: 1.5px solid #000000;
  padding-left: 36px !important;
  border-radius: 4px;
}

.search::placeholder {
  color: #808080 !important;
}

.search-container {
  position: relative;
}

.filter-container {
  min-width: 294px !important;
  max-width: 375px !important;
  max-height: 42px !important;
  border: 1.5px solid #000000;
  border-radius: 4px;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  width: 16px;
  height: auto;
}

.selected-image {
  height: 120px !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px;
}

@media (min-width: 992px) {
  .description-area-width {
    width: 351px !important;
  }
}

.file-upload-border {
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
}

.file-upload-box {
  width: 105px;
  height: 105px;
}

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
  margin-left: 0.75rem;
}

.users-length-color {
  background-color: #e1f3e9 !important;
  border: 0;
}

.creator-list-circle {
  width: 27px;
  height: 27px;
  text-align: center;
}

.grey-color {
  color: var(--gray-color) !important;
}

.dotted-border {
  border: 1px dashed #dadada;
}

.older-comments-button {
  height: 32px;
  background-color: var(--task-bg-color) !important;
  border: 0px;
}

@media (min-width: 992px) {
  .modal-dialog {
    min-width: 700px;
  }
}

.css-13cymwt-control {
  border-width: 0px !important;
  background-color: transparent !important;
}

.css-13cymwt-control:hover {
  border-width: 0px !important;
  background-color: #dee3e1 !important;
  cursor: pointer;
}

.css-t3ipsp-control {
  border-color: #f4f4f4 !important;
  box-shadow: 2px 2px 8px 0px rgba(151, 151, 151, 0.25) !important;
}

.css-t3ipsp-control:hover {
  border-color: #f4f4f4 !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.css-tj5bde-Svg {
  display: inline-block !important;
}

.css-1dimb5e-singleValue {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--title-color) !important;
}

.css-1nmdiq5-menu {
  width: 130px !important;
}

.modal-information-edit-box {
  background-color: #eef1f0;

  @media (min-width: 992px) {
    width: 290px;
    height: 290px;
  }
}

@media (min-width: 992px) {
  .modal-information-box {
    width: 270px;
    height: 147px;
    background-color: #eef1f0;
  }
}

.modal-information-box {
  background-color: #eef1f0;
}

.button-size {
  width: 50px;
}

.text-underline {
  text-decoration: underline !important;
}

.integration-card {
  background-color: var(--plain-white) !important;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 20px;
}

.integration-url-card {
  min-height: 200px !important;
  background-color: var(--plain-white) !important;
}

.non-editable {
  margin-top: -28px;
  color: red;
}

.yourtrack-width {
  width: 40px;
}

.error {
  color: red;
}

.view-details-button {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.comment-width {
  width: 50% !important;
}

.selected {
  border: 2px solid var(--primary-color);
  border-radius: 50%;
}

.single-user {
  border-bottom: 1px solid #f4f4f4 !important;
}

.user-circle {
  display: inline-block;
}

.userList-dropdown {
  width: 190px;
  z-index: 10000 !important;
  border: 1px solid #f4f4f4 !important;
  background-color: white !important;
  box-shadow: 12px 12px 12px 0 rgba(195, 195, 195, 0.25);
  border-radius: 5px;
}

.frequency-user-list-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e1f3e9;
}

.table-border {
  border-bottom: 1px solid #f4f4f4 !important;
}

.dropdown-menu-project {
  width: 180px;
}

.dropdown-menu-project1 {
  transform: translate3d(-12px, 0px, 0px) !important;
  width: 180px;
}

.table-row {
  .svg-icon svg {
    width: 24px;
    height: 24px;
  }
}

.script-code-box > pre {
  margin-bottom: 0 !important;
}

.script-background {
  border-radius: 0.625rem !important;
  background: rgb(68, 68, 68) !important;
  text-wrap: wrap !important;
}

@media (max-width: 450px) {
  code.language-html {
    font-size: 12px !important;
  }
}

#project-setting .row > * {
  padding-right: 0 !important;
}

.comment-tabs-color {
  color: var(--comment-tabs-color) !important;
}

.project-details-card {
  min-height: 200px !important;
  background-color: var(--plain-white) !important;
}

.image-url-max-width {
  max-width: 200px !important;
}

.badge-integration {
  background-color: rgba(35, 136, 86, 0.4);
  color: #238856;
  font-weight: normal !important;
}

.btn-orange {
  background-color: #e79722 !important;
  font-weight: 500;
  color: white;
  font-size: 12px !important;
}

.text-area-edit {
  border-radius: 8px !important;
  border-color: #f4f4f4 !important;
}

.dashed-red {
  height: 32px !important;
}

@media (max-width: 550px) {
  .dropdown-project {
    position: relative;
    left: 15px;
  }
}

@media (max-width: 550px) {
  .btn-close {
    position: relative !important;
    top: 0px !important;
  }
}

.team-dropdown__menu {
  width: 100% !important;
}

.project-member {
  width: 100%;
}

.user-modal .modal-content {
  width: 70% !important;
  margin: auto !important;
}

.custom-select {
  width: 50%;
  min-height: 48px;
  border-style: none;

  .css-13cymwt-control {
    min-height: 48px;
  }

  .css-t3ipsp-control {
    min-height: 48px;
  }
}

.project-hover:hover {
  color: var(--plain-white);
  background-color: var(--primary-color);
}
