$border-color: #e1e7ed;
$background-color-light: #ffffff;
$background-color-even: rgb(238, 239, 239);
$background-color-header: #f8f9fa;
$tooltip-bg-color: #333;
$tooltip-text-color: #fff;

.rb-tabs-content-item {
  .body {
    position: relative;
    .table-responsive {
      position: absolute;
    }
    .sidebar {
      background: #ffffff;
      z-index: 1;
    }
  }
}
.table-container {
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    th,
    td {
      border: 1px solid $border-color;
      padding: 8px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    th {
      background-color: $background-color-header;
    }

    td {
      background-color: $background-color-light;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 15%;
      text-align: center;
    }

    th:nth-child(2),
    td:nth-child(2) {
      width: 10%;
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 10%;
    }

    th:nth-child(4),
    td:nth-child(4) {
      width: 20%;
      position: relative;
    }

    th:nth-child(5),
    td:nth-child(5) {
      width: 15%;
    }

    th:nth-child(6),
    td:nth-child(6) {
      width: 15%;
    }

    th:nth-child(7),
    td:nth-child(7) {
      width: 15%;
    }

    tr:nth-child(even) {
      td {
        background-color: $background-color-even; // light grey for even rows
      }
    }

    td.domain-cell {
      cursor: pointer;
      position: relative;
    }

    td.domain-cell:hover::after {
      content: attr(data-full-url);
      position: absolute;
      background-color: $tooltip-bg-color;
      color: $tooltip-text-color;
      padding: 5px;
      border-radius: 3px;
      top: 100%;
      left: 0;
      white-space: nowrap;
      z-index: 1;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .table-responsive {
    overflow-y: scroll;
    transform: translateZ(0);
  }

  &.w-100 {
    width: 100%;
  }

  &.w-80 {
    width: 80%;
  }
}

.details-container {
  transition: all 0.3s ease;
  width: 400px;
  height: 500px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  overflow: auto;
  border-left: 1px solid rgb(205, 205, 205);
  background-color: rgb(251, 251, 251);
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 10px;
  }

  @media (max-width: 576px) {
    width: 350px;
  }

  @media (max-width: 375px) {
    width: 250px;
  }
}

.h-5px {
  height: 5px;
  width: 5px;
}

.close-button {
  font-size: 15px;
  background: none;
  cursor: pointer;
  position: absolute;
  z-index: 10;
}

.label-filter {
  cursor: pointer;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;

  &.active {
    background-color: black;
    color: white;
  }

  &:not(.active) {
    background-color: rgb(238, 239, 239);
    color: black;
  }
}

.pointer {
  cursor: pointer;
}
.filter-row {
  background-color: rgb(238, 239, 239);
  position: relative;
  border-radius: 4px;
}

.search-bar {
  width: 294px !important;
  height: 42px !important;
  padding-left: 36px !important;
  background-color: rgb(238, 239, 239);
  border-radius: 4px;
}

.search-img-icon {
  position: absolute;
  top: 24px;
  left: 15px;
  transform: translateY(-50%);
  width: 16px;
  height: auto;
  @media (max-width: 500px) {
    top: 8.5%;
  }
}

.request-method {
  position: relative;
  right: 25px;
}

.sideBarResizeContainer {
  width: 30%;
}
.sidebar {
  min-width: 200px;
  width: 400px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
}

.withMargin {
  margin: 10px;
  box-sizing: border-box;
}

.content {
  flex-grow: 2;
  background: white;
}

.customHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100px;
  margin: 0px -5px;
  background: rgb(6, 89, 37);
  border: 2px solid gray;
  border-radius: 2px;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.customResizeBorder {
  cursor: ew-resize;
  width: 5px;
  background: gray;
  display: flex;
  z-index: 99999;
  align-items: center;
  align-content: center;
  justify-content: center;
  overflow: visible;
}

.ResizePanel-module_ContainerHorizontal__1uF_- {
  height: 500px !important;
}
.table tr:first-child,
.table th:first-child,
.table td:first-child {
  padding-left: 15px !important;
}
