.font-14 {
  font-size: 14px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-24 {
  font-size: 24px;
}
