.task-detail-info-box {
  border: 1px solid #eef1f0;
}

.task-details {
  background-color: var(--plain-white) !important;

  @media (min-width: 992px) {
    max-width: 550px;
    margin-top: -23px;
    margin-right: -26px;
    height: 93vh !important;
    overflow-y: scroll;
  }
  @media (max-width: 992px) {
    width: 100% !important;
  }
}

.detailed-image {
  max-width: 700px;
}

.task-detail-section-width {
  min-width: 500px !important;
  margin-right: -25px;
  background-color: var(--plain-white) !important;
}

.line-break {
  border: 1px solid #eef1f0;
}

.image-line-break {
  border: 1px solid #eef1f0;
  margin-left: -25px;
}

.public-page-border {
  border-bottom: 1px solid #efe6ef !important;
}

.public-search-rensponsive {
  height: 42px !important;
  border: 1.5px solid #000000;
  padding-left: 36px !important;
  border-radius: 4px;
}
.carousel-code {
  overflow-x: hidden !important;
}

.task-modal-height {
  max-height: 350px !important;
  overflow-y: scroll;
}

.all-tabs-code {
  background-color: #ffffff !important;
}
.warning-title {
  font-size: 12px;
  color: var(--title-color);
}

.task-details-public-page {
  margin-right: 0px !important;
  margin-top: 0px !important;
}
