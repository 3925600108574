//
// Main init file of global bootstrap and theme functions, mixins, variables and config
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
//

// Custom functions & mixins
@import './core/base/functions';
@import './core/base/mixins';
@import './core/components/mixins';
@import './core/vendors/plugins/mixins';

// Custom variables
@import 'components/variables.custom';
@import './core/components/variables';

// Bootstrap initializaton
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

// 3rd-Party plugins variables
@import './core/vendors/plugins/variables';

// Custom layout variables
@import './core/layout/base/variables';
@import 'layout/variables.custom';
$size: 40px;

body {
  background-color: $body-bg-color;
  font-family: 'Inter', sans-serif;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input#session-date {
  display: inline-block;
  position: relative;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.text-clr38 {
  color: #383940;
}

.text-clr88 {
  color: #8898a6;
}

.text-clr58 {
  color: #585960;
}

.text-clr88 {
  color: #8898a6 !important;
}

.text-clr5E {
  color: #5e6278 !important;
}

.text-clrA1 {
  color: #a1a5b7;
}

.text-clrA8 {
  color: #a8b3bf !important;
}

.text-clr3F {
  color: #3f4254 !important;
}

.text-clr18 {
  color: #181c32 !important;
}

.bg-clrDB {
  background-color: #dbdcde;
}

.bg-clrF5 {
  background-color: #f5f8fa;
}

.bg-clrF3 {
  background-color: #f3f6f9 !important;
}

.form-check-input:checked[type='checkbox'] {
  background-size: 14px;
}

.textInputCheckboxLabel {
  color: var(--gray-color);
}

.border-color {
  border-color: #e1e7ed !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-14 {
  font-size: 14px;
}

@media (max-width: 992px) {
  .font-size-md-12 {
    font-size: 12px !important;
  }
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-28 {
  font-size: 28px !important;
}

.height-36 {
  height: 36px !important;
}

.c-profile {
  width: $size;
  height: $size;
  border: 0.5px solid rgb(212, 206, 206);
  border-radius: 50%;
  box-shadow: 0px 3px 8px rgba($black, 0.2);
  display: inline-block;
  background: white;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  @include transition;

  &:nth-of-type(n + 2) {
    margin-left: $size * -0.4;
  }
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.bg-clrf5f8 {
  background-color: #f5f8fa;
}

.w-md-85 {
  width: 85%;
}

.setting-tabs .active {
  color: var(--blue-color) !important;
}

.industry-focus-container {
  .card-container {
    border: 1px solid #4776e6 !important;
    width: auto;
  }
}

.custom-button-property {
  font-weight: 600;
  font-size: 14px !important;
  border-radius: 4px !important;
  padding: 11px 26px !important;
}

.rb-tabs,
.rb-tabs .rb-tabs-header {
  width: 100%;
}

.padding-16px {
  padding: 16px;
}

.height-36px {
  height: 36px !important;
}

.height-42px {
  height: 42px;
}

.width-36 {
  width: 36px !important;
}

.menu-link:hover .menu-title {
  color: #4776e6;
}

.btn_bg_color {
  background-color: var(--primary-color) !important;

  &:hover {
    background-color: var(--primary-color) !important;
  }
}

.btn-disable-color {
  background-color: var(--primary-color) !important;
}

.gap-20 {
  margin-bottom: 20px;
}

.login-input {
  height: 48px !important;
  background-color: var(--white-color) !important;
}

.link-primary {
  color: var(--blue-color) !important;

  &:hover {
    color: var(--blue-hover-color) !important;
  }
}

.link-grey {
  color: grey !important;

  &:hover {
    color: black !important;
  }
}

.h-52px {
  height: 52px;
}

.h-49px {
  height: 49px;
}

.mb-24px {
  margin-bottom: 24px;
}

.mb-17px {
  margin-bottom: 17px;
}

.-mr-6px {
  margin-right: -6px;
}

.text-clre88 {
  color: var(--primary-color);
}

.active-tab {
  background-color: var(--primary-color) !important;
}

.setting-tabs-list .active {
  color: var(--primary-color) !important;
}

@media (max-width: 570px) {
  .active-tab {
    display: none;
  }

  .setting-tabs-list {
    overflow-x: scroll;
    -ms-overflow-style: none;
  }

  .setting-tabs-list::-webkit-scrollbar {
    display: none;
  }

  .setting-tabs-list .active {
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 6px !important;
  }
}

.btn-with-img {
  border: 1px solid var(--blue-color) !important;
  color: var(--blue-color) !important;

  &:hover {
    color: var(--blue-hover-color) !important;
    border: 1px solid var(--blue-hover-color) !important;
  }

  span {
    line-height: 16px !important;
  }
}

.white-color {
  color: var(--white-color) !important;
}

.text-underline {
  text-decoration: underline !important;
}

@media (max-width: 500px) {
  .rb-tabs .rb-tabs-header .rb-tabs-items .rb-tabs-item {
    font-size: 12px !important;
  }
}

@media (max-width: 450px) {
  .font-size-9 {
    font-size: 9px !important;
  }
}

.z-index-200 {
  z-index: 200 !important;
}

.-mt-23 {
  margin-top: -23px;
}
