.sidebar_bg_color {
  background-color: var(--sidebar-dark-blue-bg-color) !important;
}

#text_color {
  color: white !important;

  &:hover {
    color: var(--primary-color) !important;
  }
}

#text_active_tab_color {
  color: var(--blue-color) !important;

  &:hover {
    color: var(--primary-hover-color) !important;
  }
}

.header_bg_color {
  background-color: var(--top-header-bg-color) !important;
}

#sidebar-logout {
  &:hover {
    background-color: var(--primary-hover-color) !important;
  }
}

.logout {
  margin-left: -4px;
}

.sidebar-menu {
  color: #fff;

  &:hover {
    color: var(--primary-hover-color) !important;
  }
}

.orange-color {
  color: var(--primary-color) !important;
}

.green-color {
  color: var(--green-color) !important;
}

.blue-color {
  color: var(--blue-color) !important;
}

.white-color {
  color: #fff !important;
}

.app-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.form-select-size {
  width: 200px !important;
  height: 40px !important;
}

.custom-height-drawer {
  height: 40px !important;
}

.custom-border {
  border: 1px inset var(--custom-border-color);
  margin-top: -2px;
}

.text-menu-light-gray {
  color: var(--light-gray-color) !important;
}

.form-menu-select-size {
  width: 77% !important;
  height: 40px !important;
}

.form-menu-select-size-collapsed {
  width: 23% !important;
  height: 40px !important;
}

/* Style the main custom select container */
.custom-select {
  position: relative;
  /* Required for absolute positioning of the options list */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

/* Style the selected display area */
.custom-select-selected {
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-select-selected-collapsed {
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Style the dropdown options list */
.custom-select-options {
  position: absolute;
  top: 100%;
  /* Place just below the selected area */
  width: 100%;
  left: 0;
  right: 0;
  background-color: #09102e;
  border: 1px solid #2b2b2b;
  border-top: none;
  /* Remove border between selected area and options */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  /* Ensure dropdown is above other content */
}

.custom-select-options li {
  padding: 12px 15px;
}

.custom-select-options li:hover {
  background-color: rgb(24, 28, 50);
}

.custom-select-border {
  border: 1px solid #2b2b2b;
}

.project-profile {
  padding: 1px 4px;
  background-color: var(--primary-color);
  border-radius: 4px;
  color: var(--plain-white);
}

.light-green {
  color: var(--primary-color) !important;
}

.project-profile-name {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}