/* Ensure the table is responsive */
.table-responsive {
  overflow-x: auto;
}

.custom-table-project table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table-project thead {
  display: table-header-group;
}

.custom-table-project thead tr {
  display: table-row;
}

.custom-table-project thead td {
  padding: 10px;
  text-align: left;
}

/* Adjust header and table for mobile view */
@media (max-width: 768px) {
  .custom-table-project thead {
    display: none;
  }

  .custom-table-project tbody {
    display: block;
    width: 100%;
  }

  .custom-table-project tbody tr {
    display: block;
    padding: 10px;
  }

  .custom-table-project tbody td {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }

  .custom-table-project tbody td:before {
    content: attr(data-label);
    flex: 1;
    text-align: left;
  }

  .custom-table-project tbody td div {
    text-align: right;
  }

  .custom-table-project tbody td[data-label='Actions'] {
    justify-content: flex-end;
  }

  .custom-table-project tbody td[data-label='Actions'] div {
    text-align: left;
  }
  .custom-table-project td {
    width: 100% !important;
  }
  .box-shodow {
    padding: 18px;
    border: 1px solid #e7e4e4;
  }
}
