.user-dropdown {
  .dropdown {
    margin: 0 auto;
    width: 170px;
    position: relative;
  }

  .dropdown .dropdown-btn {
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #777;
    font-weight: 500;
  }

  .dropdown-content {
    position: absolute;
    z-index: 2;
    left: 0;
    width: 100%;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
    background: white;
  }

  .dropdown-content .item {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-content .item:hover {
    background: #eef1f0;
  }
}
