//
// Close
//

.btn-close {
  color: var(--kt-btn-close-color);
  background-image: var(--kt-btn-close-bg);
  background-position: center;

  &:hover {
    color: var(--kt-btn-close-color);
  }
}

@media (max-width: 550px) {
  .btn-close {
    position: relative;
    top: -57px;
  }
}
