.non-active {
  font-size: 16px;
  outline: none !important;
  border: 0;
  color: #4776e6;
  padding: 8px 16px;
  background-color: rgba(71, 118, 230, 0.1);
  height: 40px;
  border-radius: 4px;
}

.activeB {
  font-size: 16px;
  outline: none !important;
  border: 0;
  color: #ffffff;
  padding: 8px 16px;
  background-color: #4776e6;
  height: 40px;
  border-radius: 4px;
}

.company-container {
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 80%;
  margin-top: 17px;
  margin: auto;
  padding: 48px 80px;
}

.professional-background {
  background-color: var(--primary-color) !important;
}

.bg-white {
  background-color: white !important;
}

.color-plan {
  color: #ff6b3d;
}

.free-color-trial {
  color: #2b3451 !important;
  font-style: italic;
  line-height: 15px;
  font-weight: 500;
}

.credit-button {
  font-weight: 500;
  font-size: 10px;
  color: #2b3451 !important;
  font-style: italic;
  line-height: 15px;
}

.border-design {
  border: 2px dotted #ff6b3d !important;
  padding: 50px !important;
}

.plan-list {
  font-size: 14px;
  color: #000;
  font-weight: 400;
  margin-bottom: 10px;
}

.active-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--dark-blue) !important;
  color: var(--plain-white) !important;
  margin: 4px;
}

@media (max-width: 550px) {
  .active-button {
    padding: 0 !important;
  }
}

.inactive-button {
  border: none;
  padding: 8px 16px;
}

@media (max-width: 550px) {
  .inactive-button {
    padding: 0;
  }
}

.font-color {
  font-size: 12px;
  font-weight: 400;
  color: var(--primary-color) !important;
}

@media (max-width: 550px) {
  .font-color {
    font-size: 10px;
  }
}

.orange {
  background-color: var(--primary-color) !important;
  color: white;
}

.price-header {
  font-size: 16px;
  font-weight: 700;
  color: var(--plain-black);
}

.price {
  color: black;
  font-size: 16px;
}

.price-text {
  font-size: 24px;
  font-weight: 700;
}

.feature {
  font-size: 14px;
  font-weight: 500;
  color: var(--plain-black);
}

.trial-btn {
  background-color: var(--plain-white) !important;
  border: 1px solid var(--title-color) !important;
  color: var(--title-color) !important;
  border-radius: 8px;
  margin: 0 auto;
  display: table;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;

  &:hover {
    background-color: var(--plain-white) !important;
    color: var(--primary-color) !important;
  }
}
.btn-contact {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}
.trial-btn-professional {
  background-color: white !important;
  color: #ff6b3d !important;

  &:hover {
    background-color: white !important;
  }
}

.center-trial-btn {
  background-color: white;
  color: black;
  border-radius: 25px;
  margin: 0 auto;
  display: table;
  font-size: 16px;
  padding: 15px;
}

.main-block {
  height: 60px;
  cursor: pointer;
  border: 4px solid var(--dark-blue);
  border-radius: 8px;
}

.time-period {
  font-size: 14px;
  font-weight: 600;
  color: var(--plain-black);
  width: 132px;
  text-align: center;
  align-items: center;
}

@media (max-width: 550px) {
  .time-period {
    width: 110px;
  }
}

.free-trial {
  font-size: 16px;
  color: var(--plain-black);
}

.currency-width {
  font-weight: 600;
  background-color: var(--plain-white);
}

.w-70 {
  width: 70%;
}

#currency {
  background-repeat: no-repeat;
  background-position: 5px center;
}

.most-popular {
  color: white;
  background-color: var(--primary-color) !important;
  font-size: 13px;
}

.selected-card {
  background-color: var(--dark-blue) !important;
}
.selected-card-color {
  color: white !important;
}
#onboarding .btn:hover {
  opacity: 1;
}

.selected-plan {
  background-color: var(--primary-color) !important;
  color: var(--plain-white) !important;

  &:hover {
    background-color: var(--primary-color) !important;
    color: var(--plain-white) !important;
  }
}

.mb-24px {
  margin-bottom: 24px;
}

.font-size-18 {
  font-size: 18px;
}

.plant {
  height: 24px;
  width: 24px;
  margin-right: 12px;
}

.loader-container {
  display: flex;
  align-items: center;
}

.loader-size {
  width: 50px;
  height: 50px;
}

.accordion-item {
  border-radius: 4px !important;
  border-width: 1px !important;
  border-color: #145da3 !important;
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.fade {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

@media (min-width: 1100px) {
  .w-lg-35 {
    width: 35%;
  }
}

.card-list {
  height: 270px;
}

.info-card {
  height: 120px;
  background-color: #f5f8fb;
}

.info-card-downgrading {
  height: 60px;
  background-color: #f5f8fb;
}

.apply-coupon-button {
  margin-left: 96px;
}

.form-check-input-coupon {
  height: 40px;
  width: 150px;
  border: 1px solid #dbdbdb;
  margin-right: 12px;
}

.info-card-description {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.strike-through {
  text-decoration: line-through;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.address-billing-column {
  width: 270px;
}

.card-on-zoom {
  padding: 24px !important;
}

@media only screen and (min--moz-device-pixel-ratio: 1.25),
  only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25) {
  .card-on-zoom {
    padding: 12px !important;
  }

  .most-popular {
    font-size: 11px;
  }

  .price {
    font-size: 14px;
  }

  .price-text {
    font-size: 20px;
  }

  .feature {
    font-size: 12px;
  }
}
