@import 'react-loading-skeleton/dist/skeleton.css';

.password {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.alert {
  border: 2px dashed #ffc700;
}

.alert-info {
  border: 2px dashed #7239ea;
}

.red-color {
  color: var(--red-color);
}

.loader {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid #5c5e5f;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 10px 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-wrapper {
  position: absolute;
  width: 6% !important;
  height: 60% !important;
  backdrop-filter: blur(1px);
  z-index: 105;

  .spinner-border {
    position: absolute;
    top: 40%;
    left: 50%;

    @media only screen and (max-width: 860px) and (min-width: 300px) {
      left: 45%;
    }
  }

  .d-center {
    top: 50%;
    left: 16%;

    @media only screen and (max-width: 500px) {
      left: 50%;
    }
  }
}

.page-spinner {
  width: 100% !important;
  height: 100% !important;

  .d-center {
    top: 50%;
    left: 50%;
  }
}

.siderbar {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.active-border {
  height: 36px;
  border-left: 3px solid #4776e6;
  color: #4776e6;
}

.form-search-select {
  background-image: url(../../../../public/media/icons/investor/search.svg);
  background-size: auto;
  cursor: pointer;
}

#date {
  outline: none;
  padding: 8px 20px;
  color: #aaa;
  border-radius: 5px;
}

.delete-modal-z-index {
  z-index: 1500 !important;
}

.date-container {
  position: relative;
  float: left;

  .date-text {
    position: absolute;
    top: 6px;
    left: 12px;
    color: #aaa;
  }

  .date-icon {
    position: absolute;
    top: 3px;
    right: 9px;
    /* pointer-events: none; */
    cursor: pointer;
    color: #aaa;
  }
}

.ql-toolbar {
  &.ql-snow {
    border-radius: 0px !important;
  }
}

.ql-container {
  .ql-snow {
    border-radius: 0px !important;
  }
}

.custom-height {
  height: var(--variable-height);
}

.m-24px {
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
}

.me-24px {
  margin-right: 24px;
}

.terms {
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-color: var(--primary-color) !important;
}

.terms:checked {
  background-color: var(--primary-color) !important;
}

.activity-spinner {
  width: 100% !important;
}

.background-white {
  background-color: var(--plain-white) !important;
  border-radius: 0.625rem;
}

// global button css

.primary_button {
  border: 0px;
  background-color: var(--primary-color) !important;
  color: var(--plain-white);

  &:hover {
    background-color: var(--primary-color) !important;
  }

  &:disabled {
    background-color: var(--primary-color) !important;
    opacity: 0.7;
  }

  span {
    line-height: 18px !important;
  }
}

.no-hover:hover {
  background-color: transparent;
  color: #198754;
}

.price-card-height {
  height: 330px !important;
}

.secondary_button {
  border: 1px solid var(--primary-color);
  background-color: var(--plain-white);
  color: var(--greencolor) !important;

  &:hover {
    color: var(--green-color) !important;
  }

  &:disabled {
    color: var(--green-color) !important;
  }

  span {
    line-height: 16px !important;
  }
}

.button_link {
  border: 0px;
  background-color: var(--plain-white);
  color: var(--blue-color) !important;

  &:hover {
    color: var(--blue-hover-color) !important;
  }

  &:disabled {
    color: var(--blue-disable-color) !important;
  }

  span {
    line-height: 18px !important;
  }
}

.w-10 {
  width: 10% !important;
}

.priority-dropdown__control {
  width: 100%;
}

.priority-dropdown__option {
  display: flex;
  align-items: center;
}

.priority-dropdown__option--is-focused {
  background-color: var(--focused-background-color);
}

.priority-dropdown__single-value {
  display: flex;
  align-items: center;
}

.priority-badge {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-right: 10px;
}

.bg-card-normal {
  background-color: var(--normal-priority-color);
}

.bg-card-critical {
  background-color: var(--critical-priority-color);
}

.bg-card-major {
  background-color: var(--major-priority-color);
}

.bg-card-minor {
  background-color: var(--minor-priority-color);
}

.add-card-modal-container {
  .modal-dialog {
    min-width: 30% !important;
  }
}

.greenTickContainer {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 992px) {
    top: 50%;
  }
}

.CircularProgressbar {
  width: 97% !important;
  vertical-align: middle;
}
